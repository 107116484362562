import Link from 'next/link';
import Image from 'next/future/image';
import { useRouter } from 'next/router';
import cookie from 'js-cookie';
import cn from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { REEBELO_STORES, ReebeloStoreT } from '@lambda/reebelo';
import { REEBELO_COUNTRY_CODE } from '@lambda/reebelo/src/types';
import settings from '@/settings';
import drones from '@/public/images/homepage/category/drones.png';
import gaming from '@/public/images/homepage/category/gaming.png';
import laptop from '@/public/images/homepage/category/laptop.png';
import more_device from '@/public/images/homepage/category/more_device.png';
import smartphone from '@/public/images/homepage/category/smartphone.png';
import smartwatches from '@/public/images/homepage/category/smartwatches.png';
import sports from '@/public/images/homepage/category/sports.png';
import kitchenappliances from '@/public/images/homepage/category/kitchenappliances.png';
import tablet from '@/public/images/homepage/category/tablet.png';

import arrowRight from '@/public/icons/homepage/arrow-right.svg';
import { Protection, Certified, Money } from '@/components/homepage/Icons';
import TrustpilotWidget from '@/components/products/TrustpilotWidget';
import useOriginCountry from '@/lib/use-origin-country';
import LangCountrySelecter from '@/components/commons/Footer/LangCountrySelecter';
import i18n from '@/settings/i18n';
import {
  useGetMixpanelDistinctId,
  useMixpanelPageViewEvent,
} from '@/lib/mixpanelUtils';
import { getSavedToken } from '@/lib/auth-token';
import { getUserAndLogBrazeEvent } from '@/components/commons/brazeUtils';
import { WebsiteSchema } from '@/components/homepage/WebsiteSchema';
import DynamicHero from '@/components/homepage/DynamicHero';

const t = i18n.init();

const HotDealSlider = dynamic(
  () => import('../components/homepage/HotDealSlider'),
  {
    ssr: false,
  },
);

const Marketplace = dynamic(
  () => import('../components/homepage/Marketplace'),
  { ssr: false },
);
const Testimonial = dynamic(
  () => import('../components/homepage/Testimonial'),
  { ssr: false },
);
const CircularEconomy = dynamic(
  () => import('../components/homepage/CircularEconomy'),
  { ssr: false },
);
const PopularSliders = dynamic(
  () => import('../components/homepage/PopularSliders'),
  { ssr: false },
);
const FeaturedClient = dynamic(
  () => import('../components/homepage/FeaturedClient'),
  { ssr: false },
);

type CategorySectionItem = {
  title: string;
  link: string;
  imageSrc: StaticImageData;
};

const CategorySelectionList: CategorySectionItem[] = [
  {
    title: 'Phones',
    link: '/collections/smartphones',
    imageSrc: smartphone,
  },
  {
    title: 'Laptops',
    link: '/collections/laptops',
    imageSrc: laptop,
  },
  {
    title: 'Tablets',
    link: '/collections/tablets',
    imageSrc: tablet,
  },
  {
    title: 'Smartwatches',
    link: '/collections/smartwatches',
    imageSrc: smartwatches,
  },
  {
    title: 'Gaming',
    link: '/collections/gaming',
    imageSrc: gaming,
  },
  {
    title: 'Drones',
    link: '/collections/camera-drones',
    imageSrc: drones,
  },
  {
    title: 'Sports',
    link: '/collections/sports',
    imageSrc: sports,
  },
  {
    title: 'More Devices',
    link: '/collections/more-devices',
    imageSrc: more_device,
  },
];

const USCategorySelectionList: CategorySectionItem[] = [
  {
    title: 'Phones',
    link: '/collections/smartphones',
    imageSrc: smartphone,
  },
  {
    title: 'Laptops',
    link: '/collections/laptops',
    imageSrc: laptop,
  },
  {
    title: 'Tablets',
    link: '/collections/tablets',
    imageSrc: tablet,
  },
  {
    title: 'Smartwatches',
    link: '/collections/smartwatches',
    imageSrc: smartwatches,
  },
  {
    title: 'Gaming',
    link: '/collections/gaming',
    imageSrc: gaming,
  },
  {
    title: 'Drones',
    link: '/collections/camera-drones',
    imageSrc: drones,
  },
  {
    title: 'Kitchen Appliances',
    link: '/collections/kitchen-appliances',
    imageSrc: kitchenappliances,
  },
  {
    title: 'More Devices',
    link: '/collections/more-devices',
    imageSrc: more_device,
  },
];

const CategorySection = (props: {
  title: string;
  link: string;
  imageSrc: StaticImageData;
}) => {
  const { title, link, imageSrc } = props;

  return (
    <div className="group rounded-md bg-white">
      <Link href={link}>
        <a className="w-full flex-col items-center justify-center text-center">
          <div className="mx-auto mt-5 w-3/4 transition-all duration-500 group-hover:scale-105 xs:w-2/3 sm:w-3/5 md:w-2/3">
            <div>
              <Image
                src={imageSrc}
                alt={title}
                sizes="(max-width: 220px) 100px"
                className="w-full object-contain"
                quality={100}
              />
            </div>
          </div>
          <div className="mb-3 flex items-center justify-center text-xs text-gray-700 xxs:text-sm sm:text-base md:text-sm xl:text-base">
            {t(title)}
            <div className="ml-1 flex w-[12px] transition-all duration-500 group-hover:-translate-x-1 xxs:ml-2 xxs:w-[13px] xs:w-[15px] sm:ml-3 sm:w-4 md:ml-2 xl:ml-3 xl:w-[18px]">
              <Image
                src={arrowRight}
                alt={title}
                width="18"
                height="18"
                sizes="100vw"
                className="w-full"
              />
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};

const TopBannerIconBox = (props: { title: string; image: ReactNode }) => {
  const { title, image } = props;

  return (
    <div className="mr-0 flex-col flex-wrap items-center justify-center text-center font-serif lg:mr-10 lg:flex lg:flex-row lg:flex-nowrap lg:justify-start lg:text-left xl:mr-14 2xl:mr-16">
      <div className="relative mr-0 mb-0 inline-block h-[18px] w-[18px] xs:h-[20px] xs:w-[20px] md:mb-1 lg:mr-2 lg:mb-0 lg:flex lg:items-center xl:mr-3">
        <div className="max-w-[18px] xs:max-w-[20px] lg:block">{image}</div>
      </div>
      <p className="text-center font-sans text-xs font-bold !leading-3 xs:text-sm xs:!leading-4 md:text-base lg:text-left lg:!leading-5 xl:text-base">
        {t(title)}
      </p>
    </div>
  );
};

const TopBannerIconSection = (props: { className?: string }) => (
  <div
    className={cn(
      props.className,
      'grid grid-cols-3 gap-1 bg-white pt-4 pb-3 xxs:pt-4 xxs:pb-3 xs:gap-4 xs:pt-6 xs:pb-5 md:gap-10 md:py-6 lg:flex lg:gap-0',
    )}
  >
    <TopBannerIconBox
      title={t`${{ warranty_month: settings.warranty_month }} Months Warranty`}
      image={<Protection />}
    />
    <TopBannerIconBox title={t`Certified Sellers`} image={<Certified />} />
    <TopBannerIconBox
      title={t`${{
        free_return_days: settings.free_return_days,
      }} Days Free Returns`}
      image={<Money />}
    />
  </div>
);
const Section = (props: { children?: ReactNode }) => (
  <div className="my-10 xxs:my-12 xs:my-14 md:my-16 lg:my-20">
    {props.children}
  </div>
);

export default function Home() {
  const router = useRouter();
  const originCountry = useOriginCountry();
  const [showCountryPopup, setShowCountryPopup] = useState<boolean>(false);
  const tokenWrapper = getSavedToken();
  const token = tokenWrapper?.token;

  useMixpanelPageViewEvent({ pageViewedEventName: 'Home Page Viewed' });

  const { mixpanelDistinctId } = useGetMixpanelDistinctId();

  useEffect(() => {
    getUserAndLogBrazeEvent({
      eventName: 'Home_Page_Viewed',
      token,
      mixpanelDistinctId,
    });
  }, [token, mixpanelDistinctId]);

  useEffect(() => {
    const { asPath = '' } = router;

    if (asPath.includes('popupFor')) {
      const splittedQS = asPath.replace(/[?/]/g, '').split('&');
      const popupForQS = splittedQS.find((value) => value.includes('popupFor'));

      if (!popupForQS) return;

      const [, store] = popupForQS.split('=');

      if (REEBELO_STORES.includes(store as ReebeloStoreT)) {
        setShowCountryPopup(false);
        cookie.set(`seen-country-popup-${store}`, 'true');
      }
    }
  }, []);

  useEffect(() => {
    const COOKIE_NAME = `seen-country-popup-${settings.store}`;
    const seenCountryPopup = cookie.get(COOKIE_NAME);

    if (
      !originCountry ||
      seenCountryPopup ||
      REEBELO_COUNTRY_CODE[settings.store] === originCountry
    )
      return;

    setShowCountryPopup(true);
    // set it to 400 days since this is the maximum value for chrome
    cookie.set(COOKIE_NAME, 'true', { expires: 400 });
  }, [originCountry]);

  type LangSelectorArgs = {
    store?: string;
  };

  const handleLangSelectorClose = (data: LangSelectorArgs | undefined) => {
    if (data && data.store) {
      const COOKIE_NAME = `seen-country-popup-${data.store}`;

      // set it to 400 days since this is the maximum value for chrome
      cookie.set(COOKIE_NAME, 'true', { expires: 400 });
    }

    setShowCountryPopup(false);
  };

  const generateCategorySelectionList = () => {
    if (settings.store === 'reebelo-ca') {
      return CategorySelectionList.filter(
        (c) =>
          c.title !== 'Gaming' &&
          c.title !== 'Drones' &&
          c.title !== 'Sports' &&
          c.title !== 'More Devices',
      );
    }

    if (settings.store === 'reebelo-us') return USCategorySelectionList;

    return CategorySelectionList;
  };

  const NewCategorySelectionList: CategorySectionItem[] =
    generateCategorySelectionList();

  return (
    <>
      <Head>
        <title>
          {settings.store === 'reebelo-us' || settings.store === 'reebelo-ca'
            ? 'Certified Refurbished Tech for Less on Reebelo'
            : t`New & Certified Refurbished Tech for Less on Reebelo`}
        </title>
        <WebsiteSchema />
        <meta
          name="description"
          content={t`	
          Empower yourself with your favorite tech from Reebelo. Enjoy up to 70% off high-quality iPhones, laptops, iPads and more. ${{
            free_return_days: settings.free_return_days,
          }} day free returns. 1 year warranty.`}
        />
      </Head>

      {/* Home Top Banner Section */}
      <DynamicHero />
      <div className="bg-white">
        <div className="reebelo-container overflow-hidden lg:-mb-6">
          <div className="flex items-center">
            <div className="w-full lg:w-[68%] xl:w-3/5">
              <TopBannerIconSection className="mt-[0px] hidden" />
            </div>
            <div className="hidden w-[32%] lg:inline-block xl:w-2/5">
              <TrustpilotWidget
                homePage={true}
                desktopAlign="right"
                mobileAlign="center"
                className="my-5 h-full"
                starClassName="h-4 w-4 xs:h-5 xs:w-5"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Home Top Banner Mobile Section */}
      <div className="block lg:hidden">
        <div className="reebelo-container border-b-2 border-gray-500 bg-white">
          <TopBannerIconSection />
        </div>
        <TrustpilotWidget
          homePage={true}
          desktopAlign="right"
          mobileAlign="center"
          className="reebelo-container mt-5 -mb-5 bg-gray-200 text-center text-sm xs:text-base"
          starClassName="h-4 w-4 xs:h-5 xs:w-5"
        />
      </div>
      <div className="reebelo-container">
        <Section>
          <div className="grid grid-cols-2 gap-2 xs:gap-3 md:grid-cols-4">
            {NewCategorySelectionList.map((category) => {
              const { title, link, imageSrc } = category;

              return (
                <CategorySection
                  title={title}
                  link={link}
                  imageSrc={imageSrc}
                  key={title}
                />
              );
            })}
          </div>
        </Section>
        <Section>
          <Marketplace />
        </Section>
        <HotDealSlider />
        <PopularSliders popularCollections={settings.home_collections_slider} />
        <Section>
          <CircularEconomy />
        </Section>
        <Section>
          <Testimonial />
        </Section>
      </div>
      <LangCountrySelecter
        isOneTimePopup={true}
        isOpen={showCountryPopup}
        onClose={handleLangSelectorClose}
      />
      <div className="bg-white py-8 xxs:py-10 md:py-12 lg:py-14">
        <div className="reebelo-container">
          <FeaturedClient />
        </div>
      </div>
    </>
  );
}
