import dayjs from 'dayjs';
import Image from 'next/future/image';
import Link from 'next/link';
import devices from '@/public/images/homepage/main-banner/devices.png';
import bfSmartphones from '@/public/images/homepage/black-friday/smartphones.png';
import bfMixer from '@/public/images/homepage/black-friday/mixer.png';
import bfController from '@/public/images/homepage/black-friday/controller.png';
import bfSmartwatch from '@/public/images/homepage/black-friday/smartwatch.png';
import blackFridayLogo from '@/public/images/homepage/black-friday/blackfriday.png';
import cyberMondayLogo from '@/public/images/homepage/black-friday/cybermonday.png';
import devicesMobile from '@/public/images/homepage/main-banner/devices_mobile.png';
import drone from '@/public/images/homepage/main-banner/drone.png';
import gifts from '@/public/images/homepage/christmas/gifts.png';
import { triggerMixpanelEvent } from '@/lib/mixpanelUtils';

const DefaultHero = () => (
  <div className="relative bg-teal-500 text-gray-700">
    <div className="reebelo-container">
      <div className="flex flex-wrap">
        <div className="w-full lg:w-9/12 xl:w-8/12">
          <h1 className="w-full pt-14 pb-6 text-center text-2xl !leading-6 xxs:text-3xl xxs:!leading-8 xs:text-4xl xs:!leading-10 sm:pb-12  md:pt-16 lg:w-3/4 lg:pb-20 lg:pt-20 lg:text-left lg:text-4xl lg:!leading-10 xl:pb-24 xl:pt-24 xl:text-5xl xl:!leading-tight">
            <b>The device</b> you want.
            <br></br>
            For a price
            <b> you’ll love.</b>
          </h1>
        </div>
        <div className="w-full lg:w-3/12 xl:w-4/12">
          <div className="hidden h-full w-full lg:flex">
            <div className="absolute left-[64vw] top-[35px] w-[320px] xl:left-[60vw] xl:top-[35px] xl:w-[430px]">
              <Image
                src={devices}
                alt="Sustainable devices"
                loading="eager"
                width={499}
                height={372}
                sizes="100vw"
                className="h-auto w-full"
              />
            </div>
          </div>
          <div className="block lg:hidden">
            <div className="relative mx-auto mb-[-4.8vw] w-[80vw] xxs:mb-[-4.9vw] xs:mb-[-4.3vw] xs:w-[70vw] md:mb-[-2.8vw] md:w-[45vw]">
              <Image
                src={devicesMobile}
                alt="Sustainable devices"
                width={526}
                height={289}
                sizes="100vw"
                priority={true}
                className="w-full"
              />
            </div>
          </div>
        </div>
        <div className="absolute top-0 right-0 w-[14%] xxs:w-[14%] sm:w-[12%] lg:w-20 xl:w-28 2xl:w-[110px]">
          <Image
            src={drone}
            alt="Reebelo"
            width={228}
            height={292}
            sizes="100vw"
          />
        </div>
      </div>
    </div>
  </div>
);

const BFHero = () => {
  // Update Cyber Monday Date in 2024
  const shouldShowCyberMonday = dayjs().isSame('2023-11-27', 'day');

  const triggerMixpanelClickEvent = () => {
    const mixpanelProps = {
      'Section Type': 'Tile',
      'Section Name': 'Hero Banner Deals',
      'Section Position': 0,
      'Card Type': 'Collection',
      'Card Name': 'Hero Banner Deals',
      'Card Position': 0,
    };

    triggerMixpanelEvent({
      eventName: 'Card Clicked',
      props: mixpanelProps,
    });
  };

  return (
    <div className="relative flex h-[275px] w-screen flex-col items-center justify-center overflow-hidden bg-[#061520] text-white">
      <div
        className="absolute top-0 left-0 z-0 h-full w-full"
        // eslint-disable-next-line react/forbid-dom-props
        style={{
          backgroundColor: 'rgba(6,21,32,1)',
          background:
            'radial-gradient(circle, rgba(146,247,250,0.2) 0%, rgba(6,21,32,1) 50%, rgba(6,21,32,1) 100%)',
        }}
      />
      <div className="relative">
        <div className="reebelo-container">
          <div className="flex flex-col items-center justify-center">
            <div className="h-[40px] sm:h-[45px] md:h-[50px]">
              <Image
                src={shouldShowCyberMonday ? cyberMondayLogo : blackFridayLogo}
                alt="Sustainable devices"
                loading="eager"
                width={368}
                height={50}
                sizes="100vw"
                className="h-full w-auto"
                priority
              />
            </div>
            <h1 className="mt-1.5 text-center text-lg font-light leading-tight text-teal-500">
              The device you want. <br className="md:hidden" />
              For a price you’ll love.
            </h1>
            <Link href="/collections/hot-deals" prefetch>
              <a
                type="button"
                className="mt-5 flex h-10 items-center justify-center rounded-full border-2 !bg-white px-5 text-xs font-semibold text-gray-700 transition duration-150 ease-in-out hover:!bg-transparent hover:text-white sm:mt-10"
                onClick={() => triggerMixpanelClickEvent()}
              >
                Shop Deals
              </a>
            </Link>
          </div>
        </div>
      </div>
      {/* Smartphones */}
      <div className="absolute bottom-0 left-[-70px] sm:left-[8%] md:left-[5%] lg:left-[10%] xl:left-[13%]">
        <div className="h-[160px] lg:h-[200px] xl:h-[210px]">
          <Image
            src={bfSmartphones}
            alt="Black Friday Smartphones"
            loading="eager"
            width={368}
            height={252}
            sizes="100vw"
            className="h-full w-auto"
            priority
          />
        </div>
      </div>
      {/* Controller  */}
      <div className="absolute top-[-5px] right-[-75px] rotate-[15deg] sm:right-[-3%] sm:top-0 sm:rotate-0 md:top-[-5%] md:right-[0%] lg:top-0 lg:right-[5%] xl:right-[13%]">
        <div className="h-[140px] md:h-[160px] lg:h-[185px] xl:h-[205px]">
          <Image
            src={bfController}
            alt="Black Friday Controller"
            loading="eager"
            width={368}
            height={252}
            sizes="100vw"
            className="h-full w-auto"
            priority
          />
        </div>
      </div>
      {/* Smartwatch */}
      <div className="absolute bottom-0 right-0">
        <div className="h-[130px] lg:h-[150px] xl:h-[180px]">
          <Image
            src={bfSmartwatch}
            alt="Black Friday Smartwatch"
            loading="eager"
            width={150}
            height={150}
            sizes="100vw"
            className="h-full w-auto"
            priority
          />
        </div>
      </div>
      {/* Mixer  */}
      <div className="absolute top-[-15px] left-[-15px] sm:top-0 sm:left-0">
        <div className="h-[110px] lg:h-[120px] xl:h-[150px]">
          <Image
            src={bfMixer}
            alt="Black Friday Mixer"
            loading="eager"
            width={150}
            height={150}
            sizes="100vw"
            className="h-full w-auto"
            priority
          />
        </div>
      </div>
    </div>
  );
};

const ChristmasHero = () => (
  <div className="relative bg-teal-500 text-gray-700">
    <div className="reebelo-container">
      <div className="flex flex-wrap">
        <div className="w-full lg:w-9/12 xl:w-8/12">
          <h1 className="w-full pt-14 pb-6 text-center text-2xl !leading-6 xxs:text-3xl xxs:!leading-8 xs:text-4xl xs:!leading-10 sm:pb-12  md:pt-16 lg:w-3/4 lg:pb-20 lg:pt-20 lg:text-left lg:text-4xl lg:!leading-10 xl:pb-24 xl:pt-24 xl:text-5xl xl:!leading-tight">
            <b>The device</b> you want.
            <br></br>
            For a price
            <b> you’ll love.</b>
          </h1>
        </div>
        <div className="w-full lg:w-3/12 xl:w-4/12">
          <div className="hidden h-full w-full lg:flex">
            <div className="absolute left-[64vw] top-[41px] w-[320px] xl:left-[60vw] xl:top-[15px] xl:w-[430px]">
              <Image
                src={gifts}
                alt="Sustainable devices"
                loading="eager"
                width={499}
                height={372}
                sizes="100vw"
                className="h-auto w-full"
              />
            </div>
          </div>
          <div className="block lg:hidden">
            <div className="relative mx-auto mb-[-8.5vw] w-[80vw] xxs:mb-[-8.35vw] xs:mb-[-7.4vw] xs:w-[70vw] md:mb-[-4.7vw] md:w-[45vw]">
              <Image
                src={gifts}
                alt="Sustainable devices"
                width={526}
                height={289}
                sizes="100vw"
                priority={true}
                className="w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const DynamicHero = () => {
  // Update Dates in 2024
  if (dayjs().isBefore('2023-11-28', 'day')) return <BFHero />;
  if (dayjs().isBefore('2023-12-28', 'day')) return <ChristmasHero />;

  return <DefaultHero />;
};

export default DynamicHero;
